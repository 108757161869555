const difference = require('lodash/difference')

interface ISignUP {
  data: {
    name: string
    emailaddress: string
    password: string
    passwordcheck: string
    locations: [string]
  }
}

export const signUp = async ({
  data: { name, emailaddress, password, locations }
}: ISignUP) => {
  const url = `https://dev.muddystilettos.co.uk/wp-json/muddy/create-account`
  const signUpData = new FormData()
  signUpData.append('username', name)
  signUpData.append('emailaddress', emailaddress)
  signUpData.append('password', password)
  if (locations.length > 0) {
    locations.forEach(county => signUpData.append('counties[]', county))
  }
  return await fetch(url, {
    method: 'post',
    body: signUpData
  })
    .then(reponse => {
      return reponse.json()
    })
    .catch(e => {
      return e.message
    })
}

interface ISave {
  counties: number[]
  email?: string
  initialCounties: number[]
}

export const savePreferences = async ({
  counties,
  email,
  initialCounties
}: ISave) => {
  const url = `https://dev.muddystilettos.co.uk/wp-json/muddy/update-mailchimp`
  const signUpData = new FormData()
  if (!email) return false

  const newCounties: number[] = difference(counties, initialCounties)
  const removedCounties: number[] = difference(initialCounties, counties)

  if (newCounties.length > 0) {
    newCounties.forEach(county =>
      signUpData.append('newcounties[]', '' + county)
    )
  }

  if (removedCounties.length > 0) {
    removedCounties.forEach(county =>
      signUpData.append('removedcounties[]', '' + county)
    )
  }
  signUpData.append('email', email)

  return await fetch(url, {
    method: 'POST',
    body: signUpData
  })
    .then(response => response.json())
    .catch(e => e.message)
}
